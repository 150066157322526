import React from 'react';
import css from './CategoryColumn.module.css';
import { Link } from 'react-router-dom'

const CategoryColumn = ({ category, level = 1, baseUrl = '' }) => {
    const nextLevel = level + 1;
    const currentUrl = `${baseUrl}pub_categoryLevel${level}=${category?.id}&`;

    return (
        <div className={css.columnContainer}>
            <Link to={`/s?${currentUrl}`} className={css.categoryColumn}>
                All {category?.name}
            </Link>
            <div className={css.categoryList}>
                {category?.subcategories && category?.subcategories?.map((subCat) => (
                    <div key={subCat?.id} className={css.subCategory}>
                        <Link to={`/s?${currentUrl}pub_categoryLevel${nextLevel}=${subCat?.id}`}>
                            {subCat?.name}
                        </Link>
                        {/* For later use for more sub level of categories */}
                        {/* {subCat?.subcategories && subCat?.subcategories?.length > 0 && (
                            <CategoryColumn category={subCat} level={nextLevel} baseUrl={currentUrl} />
                        )} */}
                    </div>
                ))}
            </div>
        </div>
    );
};

export default CategoryColumn;

